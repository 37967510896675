* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
  margin-top: 70px;
}

.main {
  width: 100;
  margin: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  text-align: center;
  /*   margin-left: 265px; */
  /* height: 100%; */
}

.sidebar {
 /*  background-color: #C7DCC7; */

   background-color: #c8dddf;
  font-family: 'Roboto';
  color: #000;
  height: 100%;
  transition: all 0, 5s;
  border: 1px solid #328AC4;
  border-color: #328AC4;
  margin-top: 70px;
  padding-bottom: 100px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
}

.top_section {
  padding: 15px 15px;
}

.logo {
  font-size: 25px;

}

.bars {
  display: flex;
  margin-left: 50px;
  font-size: 25px;
  color: #16B9CF;
}

.link {
  display: flex;
  color: #000;
  padding: 7px 5px;
  gap: 15px;
  transition: all 0.5s;

  /* Esto equilibria lo del aside */
  position: relative;
}

.active {
  background-color: lightblue;
  color: #000;
}

.link_text {
  margin-left: 2px;
}

.icon,
.link_text {
  font-size: 10px;
}


/* Media query para pantallas aún más pequeñas */
@media screen and (max-width: 576px) {
  .main {
    margin-left: 0px;
    width: 100%;
  }
}

/* @media screen and (min-width: 768px) {} */

/* @media screen and (min-width: 992px) {} */